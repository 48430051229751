import React from "react"
import classNames from "classnames"

import styles from "./utils/vaxOrdering.module.scss"

const VaxOrdering = () => {
  return (
    <div className="mt-5">
      <iframe
        className={classNames("airtable-embed", styles["vaxForm"])}
        src="https://airtable.com/embed/shrEu6T3Kb2WAoLCc?backgroundColor=red"
        frameborder="0"
        onmousewheel=""
        width="100%"
      ></iframe>
    </div>
  )
}

export default VaxOrdering
